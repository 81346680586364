// ngSticky - *CUSTOMIZED* from version 1.8.3
// https://github.com/d-oliveros/ngSticky
// David Oliveros <dato.oliveros@gmail.com>
// A simple, pure javascript (No jQuery required!) AngularJS directive to make elements stick when scrolling down.
(function() {
  'use strict'

  const module = angular.module('sticky', [])

  /**
   * Directive: sticky
   */
  module.directive('sticky', ['$window', '$timeout', function($window, $timeout) {
    return {
      restrict: 'A', // this directive can only be used as an attribute.
      scope: {
        disabled: '=disabledSticky',
      },
      link: function linkFn($scope, $elem, $attrs) {
        // Setting scope
        const scrollableNodeTagName = 'sticky-scroll' // convention used in the markup for annotating scrollable container of these stickies
        let stickyLine
        let stickyBottomLine = 0
        let placeholder
        let isSticking = false
        let originalOffset
        let originalInitialCSS

        // Optional Classes
        const bodyClass = $attrs.bodyClass || ''

        // Find scrollbar
        const scrollbar = deriveScrollingViewport($elem)

        // Define elements
        const windowElement = angular.element($window)
        const scrollbarElement = angular.element(scrollbar)
        const $body = angular.element(document.body)

        // Define options
        const usePlaceholder = ($attrs.usePlaceholder !== 'false')
        const anchor = $attrs.anchor === 'bottom' ? 'bottom' : 'top'
        const confine = ($attrs.confine === 'true')
        // flag: can react to recalculating the initial CSS dimensions later as link executes prematurely. defaults to immediate checking
        const isStickyLayoutDeferred = $attrs.isStickyLayoutDeferred !== undefined ? ($attrs.isStickyLayoutDeferred === 'true') : false

        // flag: is sticky content constantly observed for changes. Should be true if content uses ngBind to show text that may vary in size over time
        const isStickyLayoutWatched = $attrs.isStickyLayoutWatched !== undefined ? ($attrs.isStickyLayoutWatched === 'true') : true
        const initialPosition = $elem.css('position') // preserve this original state

        const offset = $attrs.offset ? parseInt($attrs.offset.replace(/px;?/, '')) : 0
        let onStickyContentLayoutHeightWatchUnbind

        // initial style
        const initialStyle = $elem.attr('style') || ''
        let initialCSS
        let wasAtTheTopAndLoaded = false

        /**
         * Initialize Sticky
         */
        function initSticky() {
          // Listeners
          scrollbarElement.on('scroll', checkIfShouldStick)
          windowElement.on('resize', $scope.$apply.bind($scope, onResize))

          memorizeDimensions() // remember sticky's layout dimensions

          // Clean up
          $scope.$on('$destroy', onDestroy)
        }

        /**
         * need to recall sticky's DOM attributes ( make sure layout has occured)
         */
        function memorizeDimensions() {
          // immediate assignment, but there is the potential for wrong values if content not ready
          initialCSS = $scope.calculateStickyContentInitialDimensions()

          // option to calculate the dimensions when layout is "ready"
          if (isStickyLayoutDeferred) {
            // logic: when this directive link() runs before the content has had a chance to layout on browser, height could be 0
            if (!$elem[0].getBoundingClientRect().height) {
              onStickyContentLayoutHeightWatchUnbind = $scope.$watch(
                function() {
                  return $elem.height()
                },

                // state change: sticky content's height set
                function onStickyContentLayoutInitialHeightSet(newValue, oldValue) {
                  if (newValue > 0) {
                    // now can memorize
                    initialCSS = $scope.calculateStickyContentInitialDimensions()

                    if (!isStickyLayoutWatched) {
                      // preference was to do just a one-time async watch on the sticky's content; now stop watching
                      onStickyContentLayoutHeightWatchUnbind()
                    }
                  }
                }
              )
            }

            // any processing for when sticky layout is immediate
          }
        }

        /**
         * Determine if the element should be sticking or not.
         */
        function checkIfShouldStick() {
          let scrollbarPosition, shouldStick, closestLine

          // Check media query and disabled attribute
          if (!wasAtTheTopAndLoaded || $scope.disabled === true || !mediaQueryMatches()) {
            checkIfScrollBarWasAtTheTopAndLoaded()
            return unStickElement()
          }

          // What's the document client top for?
          scrollbarPosition = scrollbarYPos()

          if (anchor === 'top') {
            if (confine === true) {
              shouldStick = scrollbarPosition > stickyLine && scrollbarPosition <= stickyBottomLine
            } else {
              shouldStick = scrollbarPosition > stickyLine
            }
          } else {
            shouldStick = scrollbarPosition <= stickyLine
          }

          // Switch the sticky mode if the element crosses the sticky line
          // $attrs.stickLimit - when it's equal to true it enables the user
          // to turn off the sticky function when the elem height is
          // bigger then the viewport
          closestLine = getClosest(scrollbarPosition, stickyLine, stickyBottomLine)

          if (wasAtTheTopAndLoaded && shouldStick && !shouldStickWithLimit($attrs.stickLimit) && !isSticking) {
            stickElement(closestLine)
          } else if (!shouldStick && isSticking) {
            unStickElement(closestLine, scrollbarPosition)
          } else if (confine && !shouldStick) {
            // If we are confined to the parent, refresh, and past the stickyBottomLine
            // We should "remember" the original offset and unstick the element which places it at the stickyBottomLine
            originalOffset = elementsOffsetFromTop($elem[0])

            unStickElement(closestLine, scrollbarPosition)
          }
        }

        /**
         * determine the respective node that handles scrolling, defaulting to browser window
         */
        function deriveScrollingViewport(stickyNode) {
          // derive relevant scrolling by ascending the DOM tree
          const match = findAncestorTag(scrollableNodeTagName, stickyNode)

          return (match.length === 1) ? match[0] : $window
        }

        /**
         * since jqLite lacks closest(), this is a pseudo emulator ( by tag name )
         */
        function findAncestorTag(tag, context) {
          let node
          const m = [] // nodelist container
          let n = context.parent() // starting point
          let p

          do {
            node = n[0] // break out of jqLite
            // limit DOM territory

            if (node.nodeType !== 1) {
              break
            }

            // success
            if (node.tagName.toUpperCase() === tag.toUpperCase()) {
              return n
            }

            p = n.parent()
            n = p // set to parent
          } while (p.length !== 0)

          return m // empty set
        }

        /**
         * Seems to be undocumented functionality
         */
        function shouldStickWithLimit(shouldApplyWithLimit) {
          if (shouldApplyWithLimit === 'true') {
            return ($window.innerHeight - ($elem[0].offsetHeight + parseInt(offset)) < 0)
          }
          return false
        }

        /**
         * Finds the closest value from a set of numbers in an array.
         */
        function getClosest(scrollTop, closestStickyLine, closestStickyBottomLine) {
          let closest = 'top'
          const topDistance = Math.abs(scrollTop - closestStickyLine)
          const bottomDistance = Math.abs(scrollTop - closestStickyBottomLine)

          if (topDistance > bottomDistance) {
            closest = 'bottom'
          }

          return closest
        }

        /**
         * Unsticks the element
         */
        function unStickElement(fromDirection) {
          $elem.attr('style', initialStyle)
          isSticking = false

          $elem
            .css('z-index', 10)
            .css('width', $elem[0].offsetWidth)
            .css('top', initialCSS.top)
            .css('position', initialCSS.position)
            .css('left', initialCSS.cssLeft)
            .css('margin-top', initialCSS.marginTop)
            .css('height', initialCSS.height)

          // $body.removeClass(bodyClass);
          // $elem.removeClass(stickyClass);
          // $elem.addClass(unstickyClass);
          //
          if (confine === true) {
            // $elem.addClass(bottomClass);

            // It's possible to page down page and skip the "stickElement".
            // In that case we should create a placeholder so the offsets don't get off.
            createPlaceholder()

            // $elem
            //  .css('z-index', 10)
            //  .css('width', $elem[0].offsetWidth)
            //  .css('top', initialCSS.top)
            //  .css('position', initialCSS.position)
            //  .css('left', initialCSS.cssLeft)
            //  .css('margin-top', initialCSS.marginTop)
            //  .css('height', initialCSS.height);
          }

          if (placeholder && fromDirection === anchor) {
            placeholder.remove()
          }
        }

        /**
         * Sticks the element
         */
        function stickElement(closestLine) {
          // Set sticky state
          isSticking = true

          initialCSS.offsetWidth = $elem[0].offsetWidth

          // $body.addClass(bodyClass);
          // $elem.removeClass(unstickyClass);
          // $elem.removeClass(bottomClass);
          // $elem.addClass(stickyClass);
          //
          createPlaceholder()

          $elem
            .css('z-index', '10')
            .css('width', $elem[0].offsetWidth + 'px')
            .css('position', 'fixed')
            .css('left', $elem.css('left').replace('px', '') + 'px')
            .css(anchor, (offset + elementsOffsetFromTop(scrollbar)) + 'px')
            .css('margin-top', 0)
            .css('display', 'inline-table')
            .css('table-layout', 'fixed')

          if (anchor === 'bottom') {
            $elem.css('margin-bottom', 0)
          }
        }

        /**
         * Clean up directive
         */
        function onDestroy() {
          scrollbarElement.off('scroll', checkIfShouldStick)
          windowElement.off('resize', onResize)

          $body.removeClass(bodyClass)

          if (placeholder) {
            placeholder.remove()
          }
        }

        /**
         * Updates on resize.
         */
        function onResize() {
          unStickElement(anchor)
        }

        /**
         * Triggered on load / digest cycle
         */
        function onDigest() {
          if (!wasAtTheTopAndLoaded || $scope.disabled === true) {
            return unStickElement()
          }

          if (anchor === 'top') {
            return (originalOffset || elementsOffsetFromTop($elem[0])) - elementsOffsetFromTop(scrollbar) + scrollbarYPos()
          }
          return elementsOffsetFromTop($elem[0]) - scrollbarHeight() + $elem[0].offsetHeight + scrollbarYPos()
        }

        /**
         * Triggered on change
         */
        function onChange(newVal, oldVal) {
          let parent, parentHeight, marginBottom
          let elementsDistanceFromTop, parentsDistanceFromTop, scrollbarDistanceFromTop, elementsDistanceFromScrollbarStart, elementsDistanceFromBottom

          if ((newVal !== oldVal || typeof stickyLine === 'undefined') &&
            (!isSticking && !isBottomedOut())) {
            stickyLine = newVal - offset

            // IF the sticky is confined, we want to make sure the parent is relatively positioned,
            // otherwise it won't bottom out properly
            if (confine) {
              $elem.parent().css({
                'position': 'relative',
              })
            }

            // Get Parent height, so we know when to bottom out for confined stickies
            parent = $elem.parent()[0]
            // Offset parent height by the elements height, if we're not using a placeholder
            parentHeight = parseInt(parent.offsetHeight) - (usePlaceholder ? 0 : $elem[0].offsetHeight)

            // and now lets ensure we adhere to the bottom margins
            // TODO: make this an attribute? Maybe like ignore-margin?
            marginBottom = parseInt($elem.css('margin-bottom').replace(/px;?/, '')) || 0

            // specify the bottom out line for the sticky to unstick
            elementsDistanceFromTop = elementsOffsetFromTop($elem[0])
            parentsDistanceFromTop = elementsOffsetFromTop(parent)
            scrollbarDistanceFromTop = elementsOffsetFromTop(scrollbar)

            elementsDistanceFromScrollbarStart = elementsDistanceFromTop - scrollbarDistanceFromTop
            elementsDistanceFromBottom = parentsDistanceFromTop + parentHeight - elementsDistanceFromTop

            stickyBottomLine = elementsDistanceFromScrollbarStart + elementsDistanceFromBottom - $elem[0].offsetHeight - marginBottom - offset + Number(scrollbarYPos())

            checkIfShouldStick()
          }
        }

        /**
         * Helper Functions
         */

        /**
         * Create a placeholder
         */
        function createPlaceholder() {
          if (usePlaceholder) {
            // Remove the previous placeholder
            if (placeholder) {
              placeholder.remove()
            }

            placeholder = $elem.clone()
            placeholder.css('visibility', 'hidden')

            $elem.after(placeholder)
          }
        }

        /**
         * Are we bottomed out of the parent element?
         */
        function isBottomedOut() {
          if (confine && scrollbarYPos() > stickyBottomLine) {
            return true
          }

          return false
        }

        /**
         * Fetch top offset of element
         */
        function elementsOffsetFromTop(element) {
          let offsetFromTop = 0

          if (element.getBoundingClientRect) {
            offsetFromTop = element.getBoundingClientRect().top
          }

          return offsetFromTop
        }

        /**
         * Check if scrollbar is loaded and if it's at the top.
         */
        function checkIfScrollBarWasAtTheTopAndLoaded() {
          const position = scrollbarYPos()

          if (!wasAtTheTopAndLoaded && position === 0) {
            wasAtTheTopAndLoaded = true
          }
        }

        /**
         * Retrieves top scroll distance
         */
        function scrollbarYPos() {
          let position

          if (scrollbar && scrollbar.pageYOffset) {
            position = scrollbar.pageYOffset
          } else if (typeof scrollbar.scrollTop !== 'undefined') {
            position = scrollbar.scrollTop
          } else {
            position = document.documentElement.scrollTop
          }

          return position
        }

        /**
         * Determine scrollbar's height
         */
        function scrollbarHeight() {
          let height

          if (scrollbarElement[0] instanceof HTMLElement) {
            // isn't bounding client rect cleaner than insane regex mess?
            height = $window.getComputedStyle(scrollbarElement[0], null)
              .getPropertyValue('height')
              .replace(/px;?/, '')
          } else {
            height = $window.innerHeight
          }

          return parseInt(height) || 0
        }

        /**
         * Checks if the media matches
         */
        function mediaQueryMatches() {
          let mediaNumber

          if ($attrs.minQuery) {
            mediaNumber = parseInt($attrs.minQuery)

            return mediaNumber && $window.innerWidth >= mediaNumber
          }

          return true
        }

        // Setup watcher on digest and change
        $scope.$watch(onDigest, onChange)

        // public accessors for the controller to hitch into. Helps with external API access
        $scope.getElement = function() {
          return $elem
        }
        $scope.getScrollbar = function() {
          return scrollbar
        }
        $scope.getInitialCSS = function() {
          return initialCSS
        }
        $scope.getAnchor = function() {
          return anchor
        }
        $scope.isSticking = function() {
          return isSticking
        }
        $scope.getOriginalInitialCSS = function() {
          return originalInitialCSS
        }
        // pass through aliases
        $scope.processUnStickElement = function(anchorToUnstick) {
          unStickElement(anchorToUnstick)
        }
        $scope.processCheckIfShouldStick = function() {
          checkIfShouldStick()
        }

        /**
         * set the dimensions for the defaults of the content block occupied by the sticky element
         */
        $scope.calculateStickyContentInitialDimensions = function() {
          return {
            zIndex: $elem.css('z-index'),
            top: $elem.css('top'),
            position: initialPosition, // revert to true initial state
            marginTop: $elem.css('margin-top'),
            marginBottom: $elem.css('margin-bottom'),
            cssLeft: $elem.css('left'),
            height: $elem.css('height'),
          }
        }

        /**
         * only change content box dimensions
         */
        $scope.updateStickyContentUpdateDimensions = function(width, height) {
          if (width && height) {
            initialCSS.width = width + 'px'
            initialCSS.height = height + 'px'
            // if a dimensionless pair of arguments was supplied.
          }
        }

        // ----------- configuration -----------

        originalInitialCSS = $scope.calculateStickyContentInitialDimensions() // preserve a copy
        // Init the directive

        initSticky()
      },

      /**
       * +++++++++ public APIs+++++++++++++
       */
      controller: ['$scope', '$window', function($scope) {
        /**
         * integration method allows for an outside client to reset the pinned state back to unpinned.
         * Useful for when refreshing the scrollable DIV content completely
         * if newWidth and newHeight integer values are not supplied then function will make a best guess
         */
        this.resetLayout = function(newWidth, newHeight) {
          const scrollbar = $scope.getScrollbar()
          const initialCSS = $scope.getInitialCSS()
          const anchor = $scope.getAnchor()
          let eBcr

          function _resetScrollPosition() {
            // reset means content is scrolled to anchor position
            if (anchor === 'top') {
              // window based scroller
              if (scrollbar === $window) {
                $window.scrollTo(0, 0)
                // DIV based sticky scroller
              } else if (scrollbar.scrollTop > 0) {
                scrollbar.scrollTop = 0
              }
            }
            // todo: need bottom use case
          }

          // only if pinned, force unpinning, otherwise height is inadvertently reset to 0
          if ($scope.isSticking()) {
            $scope.processUnStickElement(anchor)
            $scope.processCheckIfShouldStick()
          }
          // remove layout-affecting attribures that were modified by this sticky
          $scope.getElement().css({
            'width': '',
            'height': '',
            'position': '',
            'top': '',
            'zIndex': '',
          })
          // model resets
          initialCSS.position = $scope.getOriginalInitialCSS().position // revert to original state
          delete initialCSS.offsetWidth // stickElement affected

          // use this directive element's as default, if no measurements passed in
          if (newWidth === undefined && newHeight === undefined) {
            eBcr = $scope.getElement()[0].getBoundingClientRect()

            newWidth = eBcr.width
            newHeight = eBcr.height
          }

          // update model with new dimensions ( if supplied from client's own measurement )
          $scope.updateStickyContentUpdateDimensions(newWidth, newHeight) // update layout dimensions only

          _resetScrollPosition()
        }

        /**
         * return a reference to the scrolling element ( window or DIV with overflow )
         */
        this.getScrollbar = function() {
          return $scope.getScrollbar()
        }
      }],
    }
  }])
}())
