/* eslint no-extend-native: 0 */

// Credit: https://gist.github.com/hbogs/7908703
if (!window.location.origin) {
  window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '')
}

this.getCookie = function(cname) {
  const name = cname + '='
  const ca = document.cookie.split(';')
  let c
  let i

  for (i = 0; i < ca.length; i++) {
    c = ca[i]

    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

this.setCookie = function(cname, cvalue, exdays) {
  const d = new Date()
  let expires = false

  if (exdays) {
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    expires = 'expires=' + d.toUTCString()
  }
  document.cookie = cname + '=' + cvalue + ';' + (expires ? (expires + ';path=/') : '')
}

// PhantomJS and IE don't understand Array.findIndex(), so we'll polyfill it here.
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function(predicate) {
      let o, len, thisArg, k, kValue

      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      o = Object(this)

      len = o.length >>> 0

      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      thisArg = arguments[1]

      k = 0

      while (k < len) {
        kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return k
        }
        k++
      }

      return -1
    },
  })
}

// IE doesn't understand Array.includes(), polyfill here
if (!Array.prototype.includes) {
  Array.prototype.includes = function(search, start) {
    if (typeof start !== 'number') {
      start = 0
    }

    return this.indexOf(search, start) !== -1
  }
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    if (typeof start !== 'number') {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    }

    return this.indexOf(search, start) !== -1
  }
}

if (!Number.isInteger) {
  // Number.isInteger is not supported in IE.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
  Number.isInteger = function(value) {
    return typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value
  }
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
      let o, len, thisArg, k, kValue
     // 1. Let O be ? ToObject(this value).

      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      thisArg = arguments[1]

      // 5. Let k be 0.
      k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return undefined.
      return undefined
    },
    configurable: true,
    writable: true,
  })
}

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict'
      let to
      let index
      let nextSource
      let nextKey

      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object')
      }

      to = Object(target)

      for (index = 1; index < arguments.length; index++) {
        nextSource = arguments[index]

        if (nextSource !== null && nextSource !== undefined) {
          for (nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true,
  })
}

if (typeof Number.isNaN !== 'function') {
  Number.isNaN = function(value) {
    return typeof value === 'number' && isNaN(value)
  }
}
